<template>
    <main class="mt-16 w-full">

        <section class="container">
            <div class="flex justify-between items-end">
                <div class="">
                    <div class="text-grey-500 text-sm"> <span>Home</span> > <span>Shop</span> </div>
                    <p class="text-2xl text-grey-900 font-bold mt-2">Artworks</p>
                </div>

                <div class="">
                    <div class="hidden lg:flex justify-end items-center">
                        <p class="text-grey-500 text-sm mr-2">Sort by</p>
                        <div class="relative">
                            <button @click="sortByList = !sortByList" class="flex items-center focus:outline-none">
                                <span class="text-grey-800 border-b border-primary-black mr-2 text-xs font-medium">{{ sortByText }}</span>
                                <span>
                                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1L4 4L1 1" stroke="black"/>
                                    </svg>
                                </span>
                            </button>

                            <div v-if="sortByList" class="absolute z-40 shadow bg-surface-white py-2 px-4 top-6 space-y-4">
                                <button @click="sortByFun('Latest')" class="text-grey-800 text-sm font-medium">
                                    Latest
                                </button>

                                <button @click="sortByFun('Oldest')" class="text-grey-800 text-sm font-medium">
                                    Oldest
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="lg:hidden">
                        <button class="flex items-center focus:outline-none">
                            <span>
                                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 0C3.15311 0 3.70873 0.417404 3.91465 1H14V2H3.91465C3.70873 2.5826 3.15311 3 2.5 3C1.84689 3 1.29127 2.5826 1.08535 2H0V1H1.08535C1.29127 0.417404 1.84689 0 2.5 0ZM11.5 5C12.1531 5 12.7087 5.4174 12.9146 6H14V7H12.9146C12.7087 7.5826 12.1531 8 11.5 8C10.8469 8 10.2913 7.5826 10.0854 7H0V6H10.0854C10.2913 5.4174 10.8469 5 11.5 5ZM7.5 10C6.84689 10 6.29127 10.4174 6.08535 11H0V12H6.08535C6.29127 12.5826 6.84689 13 7.5 13C8.15311 13 8.70873 12.5826 8.91465 12H14V11H8.91465C8.70873 10.4174 8.15311 10 7.5 10ZM2.02819 1.66675L1.96925 1.5L2.02819 1.33325C2.09731 1.1377 2.28386 1 2.5 1C2.71614 1 2.90269 1.1377 2.97181 1.33325L3.03075 1.5L2.97181 1.66675C2.90269 1.8623 2.71614 2 2.5 2C2.28386 2 2.09731 1.8623 2.02819 1.66675ZM11.0282 6.66675L10.9693 6.5L11.0282 6.33325C11.0973 6.1377 11.2839 6 11.5 6C11.7161 6 11.9027 6.1377 11.9718 6.33325L12.0307 6.5L11.9718 6.66675C11.9027 6.8623 11.7161 7 11.5 7C11.2839 7 11.0973 6.8623 11.0282 6.66675ZM6.96925 11.5L7.02819 11.3332C7.09731 11.1377 7.28386 11 7.5 11C7.71614 11 7.90269 11.1377 7.97181 11.3332L8.03075 11.5L7.97181 11.6668C7.90269 11.8623 7.71614 12 7.5 12C7.28386 12 7.09731 11.8623 7.02819 11.6668L6.96925 11.5Z" fill="black"/>
                                </svg>
                            </span>
                            <span class="uppercase text-xs sm:text-sm text-grey-900 ml-3">Filter</span>
                        </button>
                    </div>
                </div>
            </div>
            <hr class="border-grey-100 w-full mt-6 hidden md:block">
        </section>

        <section id="Featured-artworks" class="mt-12 container">

            <div v-if="artworksLoading" class="animate-pulse grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-12">
                <div class="group">
                    <div class="border border-grey-100 p-8 md:p-10 lg:p-20 block">
                        <div class="w-full h-64 bg-surface-grey"></div>
                    </div>
                    <div class="mt-6 bg-surface-grey w-64 h-6 mx-auto"></div>
                    <div class="mt-4 bg-surface-grey w-48 h-6 mx-auto"></div>
                </div>
                <div class="group">
                    <div class="border border-grey-100 p-8 md:p-10 lg:p-20 block">
                        <div class="w-full h-64 bg-surface-grey"></div>
                    </div>
                    <div class="mt-6 bg-surface-grey w-64 h-6 mx-auto"></div>
                    <div class="mt-4 bg-surface-grey w-48 h-6 mx-auto"></div>
                </div>
            </div>

            <div v-else class="grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-12">
                <router-link :key="index" v-for="(artwork, index) in artworks" :to="{ name: 'artwork', params: { id: artwork.artworkId } }" class="group">
                    <span class="border border-grey-100 p-8 md:p-10 lg:p-20 block group-hover:border-grey-300 transform duration-700">
                        <img :src="artwork.artWorkImage" alt="" class="w-full h-64 object-contain">
                    </span>
                    <span class="mt-6 text-xl xl:text-2xl text-grey-900 font-bold block text-center">
                        {{ artwork.artworkName }}
                    </span>
                    <span class="mt-4 text-sm text-grey-600 font-semibold block text-center">
                        {{ artwork.producerName }}
                    </span>
                </router-link>
            </div>

        </section>

    </main>
</template>
<script>
export default {
    components: {

    },
    mounted() {
        window.scroll(0, 0)
        this.getArtwroks();
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 100,
            
            artworks: [],

            artworksLoading: true,
            sortByList: false,
            sortBy: '',
            sortByText: 'Latest'
        }
    },
    methods: {

        sortByFun(sort){
            this.sortBy = sort;
            this.sortByText = sort;
            this.getArtwroks();
        },
        getArtwroks() {
            this.$http.homeService
            .GetArtwroks(this.pageNo, this.pageSize, this.sortBy)
            .then((res) => {

            setTimeout(() => {
                this.data = res.data;
                this.artworks = res.data.result.artworks;

                this.artworksLoading = false;
            }, 500);
            
            })
            .catch((err) => {
            console.log(err);
            });
        },
    }
}
</script>
